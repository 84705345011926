.profesional-list{
    padding: 2em;
}
.profesional-item{
    display: flex;
    justify-content: space-between;
    margin-right: 1em;
}

.profesional-list .button-container{
    display: flex;
    gap: 1em;
}

.profesional-list .button{
    border: none;
    background: transparent;
    font-weight: 600;
    font-size: var(--bs-body-font-size);
}