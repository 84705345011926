.progess-container{
    margin-top: 4em;
}
.progress-bar-envio {
    background-color: #ccc;
    height: 20px;
    border-radius: 5px;
    margin: 20px 0;
  }
  
.progress-bar-envio .progress {
    height: 100%;
    background-color: var(--icon-color); /* Color de la barra de progreso */
    border-radius: 5px;
    transition: width 0.3s ease-in-out;
}