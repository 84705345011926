.datos-container{
    margin: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 20em;
    margin-top: 10em;
    margin-bottom: 5em;
    width: 800px;
 }
 .section-area {
    color: var(--icon-color);
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
  }
  .title-datos{
    font-weight: bold;
    font-size: 2em;
  }
  .gris-subtitle{
    color:var(--grey-color);
    text-wrap: pretty;
    font-size: 1em;
 }
 .personales-container{
        min-width: 800px;
        padding: 2em;
        margin-top: 2em;
        border: 1px solid var(--border-bottom-color);
        border-radius: 5px;  
 }
 .orden-header{
    display: flex;
    justify-content: space-between;
 }
 .productos-container{
        display: grid;
        grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
        grid-template-rows: 2fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        justify-items: start;
        align-items: center;
 }
 .gris-precio{
    color:var(--grey-color);
    font-size: 1em;
    /* margin-left: 40px; */
 }
 .gris-cantidad{
    color:var(--grey-color);
    text-wrap: pretty;
    font-size: 1em;
    margin-left: 50px;
 }
 .envio-container{
    display: flex;
 }
 .gris-envio{
    color:var(--grey-color);
    text-wrap: pretty;
    font-size: 1em;
    margin-left: 41%;
 }
 .pedidos-container{
   display: flex;
   justify-content: space-between;
   align-items: first baseline;
   padding-top: 1em;
   border-top: 1px solid var(--border-bottom-color);
 }
 .total{
   font-weight: bold;
   font-size: 1.2em;
 }
 .seguimiento-button{
   color: var(--icon-color);
   font-weight: bold;
   text-decoration: none;
 }
 .empty-container-pedidos{
   min-width: 800px;
   /* height: 200px; */
   padding: 2em;
   margin-top: 2em;
   border: 1px solid var(--border-bottom-color);
   border-radius: 5px;  
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 1em;
   margin-bottom: 10em;
 }
