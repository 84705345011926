.drop-file-input {
  height: 500px;
  position: relative;
}

.drop-file-input:hover, .drop-file-input.dragover {
  opacity: 0.6;
}

.drop-file-input #green-text {
  color: #5cb8b2;
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}