 
 .registro-container{
    margin: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 20em;
    margin-top: 5em;
    margin-bottom: 5em;
    max-width: 800px;
 }
 .registro-container .title-registro{
   font-weight: bold;
   font-size: 2em;
   padding-bottom: 1em;
 }
 .registro-container .subtitle{
   font-weight: bold;
   font-size: 1.2em;
   margin-top: 1em;
   margin-bottom: 1em;
 }
 .registro-container .form-registro {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
    width: 800px; 
 }
 .registro-container label{
    color: var(--grey-color);
    margin-left: 0.5em;
    margin-right: 0.5em;
 }

 .registro-container .input-form{
  height: 4em;
 }
 .registro-container input{
    font-size:small;
    border: 1px solid var(--border-bottom-color);
    padding-left: 1em;
    color: var(--grey-color);
 }
 .registro-container .radio-input {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .registro-container .radio-input input {
    appearance: none;
    width: 2em;
    height: 2em;
    background-color: var(--white-color);
    border-radius: 50%;
    transition: .4s ease-in-out;
    border: 1px solid var(--icon-color);
  }
  
  .registro-container .radio-input input:hover {
    scale: 1.2;
    cursor: pointer;
    box-shadow: none;
  }
  .registro-container .radio-input .circle {
    position: relative;
    top: 0.01em;
    left: -1.1em;
    width: 0.6em;
    height: 0.6em;
    background-color: var(--icon-color);
    scale: 0;
    border-radius: 10px;
    transition: .4s ease-in-out;
  }
  .registro-container .radio-input input:checked {
    border: 1px solid var(--icon-color);
  }
  .registro-container .radio-input input:checked + .circle {
    transform: rotate(360deg);
    scale: 1;
  }
  
.submit-button{
    background-color: var(--icon-color);
    color: var(--primary-color);
    border: none;
    padding: 1em;
    margin-top: 4em;
    font-weight: bold;
    text-transform: uppercase;
    width: 170px;
}

.section-area {
   color: var(--icon-color);
   text-transform: uppercase;
   font-weight: bold;
 }
 .registro-container .gris{
   color:var(--grey-color);
   padding-right: 15em;
   padding-bottom: 1em;
   border-bottom: 1px solid var(--border-bottom-color);
   text-wrap: pretty;
}


  