.medios-envio-container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 40% 55%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-items: center;
    align-items: center;
}

.medios-envio-container label{
    color: var(--grey-color);
    margin-bottom: 0.5em;
}

.medios-envio-container input{
    border: 1px solid var(--border-bottom-color);
    padding-left: 0.5em;
     height: 3em;
}

.medios-envio-container .left-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15em;
    padding-right: 7em;
    background-color: var(--background-color-cart);
}

.medios-envio-container .right-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.medios-envio-container .title-container{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
    width: 80%;
    border-bottom: 1px solid var(--border-bottom-color);
    padding-bottom: 1em;
    margin-bottom: 2em;
}
.medios-envio-container .title-container p.section-area{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.medios-envio-container .section-area{
    color: var(--icon-color);
    text-transform: uppercase;
    font-weight: bold;
}
.medios-envio-container .title-container h2{
    font-weight: bold;
}
.medios-envio-container .right-form{
    width: 80%;
    border: 1px solid var(--border-bottom-color);
    border-radius: 0.5em;
}
.medios-envio-container .form-header{
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding: 1em;
}
.medios-envio-container .transporte-title-container{
    margin-left: 1em;
}
.medios-envio-container .transporte-title-container h3{
    font-size: 1.3em;
    font-weight: bold;
}
.medios-envio-container .transporte-title-container p{
    color: var(--grey-color);
}
.medios-envio-container .form-header-left{
    display: flex;
}
.medios-envio-container .form-header-right{
    display: flex;
    justify-content: center;
    align-items: center;
}
.medios-envio-container .form-header-right p{
    padding: 0;
    margin: 0;
    padding-right: 1em;
    font-weight: bold;
}

.medios-envio-container .form-body{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
    padding: 1em;
}
.medios-envio-container .form-body p{
    color: var(--grey-color);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    margin: 0;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
}
.medios-envio-container .selector-container{
    display: flex;
    justify-content: space-between;
}
.medios-envio-container .selector-container select{
    width: 40%;
    border: 1px solid var(--border-bottom-color);
    color: var(--grey-color);
}
.medios-envio-container .form-footer{
    padding-left: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
}


/* checkbox */
/* Create a custom checkbox */
/* Hide the default checkbox */
.option-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .option-checkbox-container {
    display: flex;
    gap: 10px;
  }
  /* Create a custom checkbox */

  .checkmark-option {
    position: relative;
    box-shadow: var(--border-bottom-color) 0px 0px 0px 2px;
    background-color: var(--white-color);
    height: 20px;
    width: 20px;
    margin-right: 10px;
    flex-shrink: 0;
    margin-top: -1px;
    transition: all 0.2s ease 0s;
    cursor: pointer;
    transform-origin: 0px 10px;
    border-radius: 4px;
    margin: -1px 10px 0px 0px;
    padding: 0px;
    box-sizing: border-box;
  }
  
  .option-checkbox-container input:checked ~ .checkmark-option {
    background-color: var(--white-color);
    height: 20px;
    width: 20px;
    margin-right: 10px;
    flex-shrink: 0;
    margin-top: -1px;
    transition: all 0.2s ease 0s;
    cursor: pointer;
    transform-origin: 0px 10px;
    border-radius: 4px;
    margin: -1px 10px 0px 0px;
    padding: 0px;
    box-sizing: border-box;
  }
  
  .checkmark-option:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .option-checkbox-container input:checked ~ .checkmark-option:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .option-checkbox-container .checkmark-option:after {
    left: 0.45em;
    top: 0.25em;
    width: 0.25em;
    height: 0.5em;
    border: solid var(--icon-color);
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
    transition: all 500ms ease-in-out;
  }