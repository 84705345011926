.checkout-container-order{
    margin-top: 10em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* max-height: 500px; */
}
.checkout-order-title-container{
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 200px;
}
.checkout-container-order .title-order{
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 1em; 
}
.checkout-container-order .subtitle-order{
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: solid 1px var(--grey-color);
    width: 100%;
}

.checkout-container-order .checkout{
        display: grid;
        grid-template-columns: 60% 40%;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        justify-items: center;
        align-items: center;
}

.checkout-container-order .columna-izquierda-order{
    /* width: 100%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30em;
}
.checkout-container-order .medios-entrega-container h2{
    border-bottom: solid 1px var(--grey-color);
    padding-bottom: 1em;
    font-weight: bold;
    font-size: 1.5em;
    margin-top: 1em;
}

.checkout-container-order .title-form{
    width: 100%;
    border-bottom: solid 1px var(--grey-color);
    padding-bottom: 1em;
    font-weight: bold;
    font-size: 1.5em;
    margin-top: 1em;
}

.checkout-container-order .contenedor-option{
    margin-top: 1em;
    width: 800px;
    display: grid;
    gap: 1em;
    padding: 1em;
    padding-right: 5em;
    grid-template-columns: 85% 15%;
    justify-items: center;
    align-items: center;
    border: solid 1px var(--grey-color);
    border-radius: 0.5em;
}
.checkout-container-order .contenedor-option-green{
    margin-top: 1em;
    width: 800px;
    display: grid;
    gap: 1em;
    padding: 1em;
    padding-right: 5em;
    grid-template-columns: 85% 15%;
    justify-items: center;
    align-items: center;
    border: solid 1px #66D9A3;
    border-radius: 0.5em;
}
.checkout-container-order .contenedor-option-grey{
    margin-top: 1em;
    width: 800px;
    display: grid;
    gap: 1em;
    padding: 1em;
    padding-right: 5em;
    grid-template-columns: 85% 15%;
    justify-items: center;
    align-items: center;
    border: solid 1px var(--grey-color);
    color: var(--grey-color);
    border-radius: 0.5em;
}


.checkout-container-order.checkout-container-order  .left-container{
    width: 100%;
    padding-left: 1em;
    display: flex;
    align-items: center;
    gap: 1em;
}

.checkout-container-order .option-title-container h3{ 
    font-size: 1em;
    padding: 0;
    margin: 0;
}
.checkout-container-order .checkbox{
    display: flex;
    gap: 1em;
    font-size: 1em;
}
.checkout-container-order .checkbox-2{
    margin-left: 5em;
    display: flex;
    align-items: center;
}

.checkout-container-order .footer-order{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.checkout-container-order .small-order{
    max-width: 50%;
    margin-top: 2em;
    color: var(--grey-color);
}
.checkout-container-order .small-order .mail{
    color: var(--icon-color);
    font-weight: bold;
    text-decoration: none;
}

.checkout-container-order .button-order{
    border: none;
    background-color: transparent;
}

.checkout-container-order .columna-derecha h2{
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 1em;
    width: 100%;
}