.config-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}
.button{
    border-radius: 10px;
    border: none;
    padding: 4px;
    margin-left: 10px;
    transition: all ease 0.1s;
    background-color: rgb(224, 224, 224);
}
.button:active {
    transform: translateY(5px);
    box-shadow: 0px 0px 0px 0px grey;
  }