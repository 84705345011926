.datos-container{
    margin: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 20em;
    margin-top: 10em;
    margin-bottom: 5em;
    width: 1000px;
 }
 .section-area {
    color: var(--icon-color);
    text-transform: uppercase;
    font-weight: bold;
  }
  .title-datos{
    font-weight: bold;
    font-size: 2em;
  }
  .gris-subtitle{
    color:var(--grey-color);
    text-wrap: pretty;
    font-size: 1em;
 }
 .personales-container{
        max-width: 800px;
        padding: 2em;
        margin-top: 2em;
        border: 1px solid var(--border-bottom-color);
        border-radius: 5px;
        
 }
 .personales{
    display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        justify-items: stretch;
        align-items: center;
 }
 .datos{
    font-weight: bold;
    font-size: 1em;
    margin-bottom: 0;
  }
  .datos-title-container{
    display: flex;
    gap: 1em;
    margin-bottom: 1em;
  }
 .datos-title{
    font-size: 1.2em;
    font-weight: bold;
 }
 
 .ubicacion{
    display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        justify-items: stretch;
        align-items: center;
 }