.init-container{
   display: flex;
   justify-content: center;
   align-items: center;
}

.boxes-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
}

