.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropbtn {
    background-color: #3498db;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    position: relative; /* Necesario para el pseudo-elemento */
  }
  
  .dropdown-content-triangulo {
    display: none;
    position: absolute;
    background-color: transparent;
    min-width: 160px;
    top: calc(100% );
    left: 0;
    z-index: 11;
  }
   .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--white-color);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    top: calc(100% + 20px);
    right: 1px;
    z-index: 10;
    border-radius: 5px;
  }
  
   .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
    .triangulo-header-1 {
    display: none;
    width: 0;
    height: 0;
    background-color: transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid var(--white-color);
} 
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .dropdown:hover .triangulo-header-1 {
    display: block;
  }
  .dropdown:hover .dropdown-content-triangulo {
    display: block;
  }
  
  