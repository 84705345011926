.control-tags .container {
  display: flex;
  border-radius: 5px;
  color: black;
  flex-wrap: wrap;
  margin-bottom: 100px;
}

.control-tags input {
  /*width: 100%;
  min-width: 50%;
  border: none;
  border-radius: 5px;
  padding: 14px;
  padding-left: 14px;
*/
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 5px;
  margin-left: 5px;
  padding: 2px 15px 2px 15px;
  white-space: nowrap;
}

.control-tags div.tag {
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 5px;
  margin-left: 5px;
  padding: 2px 15px 2px 15px;
  border-radius: 15px;
  background-color: var(--bs-secondary);
  white-space: nowrap;
  color: white;
  font-weight: normal;
}

.control-tags .tag button {
  min-width: 10px !important;
  display: flex;
  border: none;
  background-color: unset;
  cursor: pointer;
  color: white;
  padding: 2px;
  margin-left: 4px;
}