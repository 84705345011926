@font-face {
	font-family: "poppins-bold";
	src: url(../../assets/poppins/Poppins-Bold.ttf);
}
@font-face {
	font-family: "roboto";
	src: url(../../assets/Roboto/Roboto-Bold.ttf);
}
@font-face {
	font-family: "roboto-light";
	src: url(../../assets/Roboto/Roboto-Light.ttf);
}

.registrate-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10em;
    padding-left: 5em;
}

.registrate-container .text-container{
    text-align: start;
    width: 50%;
    /* padding-left: 10em; */
    padding-right: 10em;
    
}

.registrate-container img {
    width: 50%;
    height: 25em;
    /* padding: 10em; */
}

.registrate-container .button-registrate{
    background-color: var(--icon-color);
    color: var(--primary-color);
    border: none;
    width: 8em;
    padding: 1em;
    margin-bottom: 1em;
    margin-top: 1em;
    text-decoration: none;
    display: block;
    font-weight: bold;
}

.registrate-container .sesion{
    font-weight: bold;
    color: var(--icon-color);
    text-decoration: none;
    font-family: "roboto";
}

.registrate-container .section-area{
    font-family: "poppins-bold";
    font-size: 16pt;
}

.registrate-container .section-title{
    font-family: "poppins-bold";
    font-size: 32pt;
}

.text-container p{
    font-family: "roboto-light";
    font-size: 16pt;
    color: var(--grey-color);
}