@font-face {
	font-family: "roboto" ;
	src: url(../../assets/Roboto/Roboto-Light.ttf);
}
@font-face {
	font-family: "roboto-bold" ;
	src: url(../../assets/Roboto/Roboto-Bold.ttf);
}

.header{
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding: 1em;
    height: 4em;
    background-color: var(--secondary-color);
    text-align: center;
    color: var(--primary-color);
}
.header a{
    font-family: "roboto";
    color: var(--primary-color);
    text-decoration: none;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header a:hover{
    border-bottom: none;
    /* color: var(--white-color); */
}
.header span{
    font-size: 1em;
    font-family: "roboto";
    color: var(--grey-color);
    padding-right: 0.2em;
}
.header .idraet-icon{
    margin-left: 1em;
}
.shoppingCart{
    fill: var(--icon-color);
    display: flex;
    align-items: baseline;
    gap: 0.5em;
}
.professionalAccess{
    display: flex;
    align-items: baseline;
    gap: 0.5em;
}
.buscador-container{
    display: flex;
}
.buscador-container .buscador{
    background-color: var(--primary-color);
    border-radius: 1em 0 0 1em;
    padding-left: 1em;
    border: none;
    width: 35em;
    height: 2em;
}
.buscador-container .borde{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 1em 1em 0;
    background-color: var(--primary-color);
    color: black;
    padding-right: 1em;
    fill: var(--icon-color);
}

.header-centro{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 60%;
}

.header .whatsapp-icon{
    display: flex;
    font-family: "roboto-bold";
    font-size: 1em;
}

.header .whatsapp-icon div{
    padding-right: 0.5em;
}