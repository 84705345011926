.box{
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: row;
    background-color: aliceblue;
    min-width: 10rem;
    gap: 1rem;
    width: 325px;
    height: 125px;
    padding: 1rem;
    margin: 1rem;
}
.box-img{
    width: 50%;
}
.text-number-container{
    width: 50%;
   
}
.box-number{
    height: 50%;
    width: 100%;
    font-size: 2rem;
    text-transform: uppercase;
    border-bottom: 1px solid;
    
}
.box-text{
    width: 100%;
    text-transform: uppercase;
    display: inline-block;
  
    
    
}



