@font-face {
	font-family: "roboto";
	src: url(../../assets/Roboto/Roboto-Bold.ttf);
}
@font-face {
	font-family: "poppins";
	src: url(../../assets/poppins/Poppins-Bold.ttf);
}

.product-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    width: 100%;
    height: 800px;
    padding: 1em;
    padding-left: 10em;
    margin-top: 160px;
}

.product-container p{
    color: var(--grey-color);
}

.product-container .img-container{
    padding-right: 2em;
    width: 100%;
    padding-left: 8em;
}

.product-container .img-container-1{
    /* border: solid red; */
    display: grid;
    grid-template-columns: 30% 1fr;
    justify-content: center;
    align-items: center;
    padding-bottom: 1em;
    /* padding-right: 2em; */
    width: 100%;
    border-bottom: solid  var(--border-bottom-color);
    /* height: 500px; */
}
.product-container .big-img{
    border: solid gray;
    width: 70%;
    min-width: 392px;
    max-width: 450px;
}
.img-container-1 .img-container-2{
    /* border: solid black; */
    display: flex;
    flex-direction: column;
    justify-content:center;
    /* align-items:center; */
    width: 50%;
    min-width: 120px;
    gap: 1em;
}

.img-container-2 img{
    border-radius: 1em;
    overflow: hidden;
}

.product-container .info-container{
    /* border: solid #111; */
    padding: 1em;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 14em;
    /* align-items:flex-start; */
    /* justify-content: space-between; */
}

.info-container .info{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
}

.info-container .info .info-header{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.zona-de-compra{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 1em;
    margin-top: 1em;
}
.zona-de-compra .button-container{
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    margin-right: 1em;
    width: 2em;


}
.zona-de-compra .button-color{
    margin-top: 0.3em;
    padding: 0;
    transform: rotate(90deg);
    border: none;
    background-color: transparent;
    height: 1em;
}
.zona-de-compra .contador{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width:3em;
    height: 2em;
    background-color: var(--background-color);
    border: none;
}

.info-container .button{
    background-color: var(--icon-color);
    color: var(--primary-color);
    border: none;
    border-radius: 0;
    padding: 1em;
    font-weight: bold;
    padding: 0.5em;
    width: 20em;
}

.info-container .categoria{
    color: var(--icon-color);
    text-transform:uppercase;
    font-weight: bold;
}

.info-container .price{
    color: var(--secondary-color);
    font-weight: bold;
    font-size: 1.5em;
    margin-top: 1em;
    margin-bottom: 1em;
}

.info-container .negrita{
    color: var(--secondary-color);
    font-weight: bold;
    font-size: 1em;
}

.info-container h2{
    font-family: "poppins";
    border-bottom: solid var(--border-bottom-color);
    width: 100%;
    padding-right: 3em;
    padding-bottom: 1em;
}

.info-container h3{
    font-weight: bold;
    width: 100%;
    padding-right: 5em;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 1em;
}
