.slider-container-nav{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items:baseline;   
	border-bottom: 1px solid var(--border-bottom-color);
}

.slider-container .button{
    border: none;
	background: transparent;
	padding: 0;
}

.slide {
	opacity: 0;
	transition: opacity 0.75s ease-in-out;
    overflow: hidden;
}

.slide.active {
	opacity: 1;
}

/* .slider-container-nav .nav-slider{

    display: flex;
    align-items:flex-start;
    justify-content: space-between;
    padding: 1em;
    margin: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    gap: 1em;
} */

.slider-container-nav .category{
    border-bottom: solid transparent;
    cursor: pointer;
    padding-bottom: 0.5em;
    margin: 0;
	margin-right: 6em;
	padding-left: 0.5em;
    font-size: 0.9em;
}

.slider-container-nav .category:hover{
    border-bottom: solid var(--icon-color) ;
}

@media screen and (max-width: 1900px ) {
    .slider-container-nav .category{
        margin-right: 3em;
    }
}

