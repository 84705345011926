.color-slider-container{
	display: flex;
	flex-direction: row;
	justify-content:space-between;
	align-items: center;
}

.color-slider-container div{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0;
	
}
.color-slider-container .button-color{
	background-color: transparent;
	border: none;
}

.color-slider-container .button{
    border: none;
	background: transparent;
	
}

.slide {
	max-width: 100%;
	height: auto;
	opacity: 0;
	transition: opacity 0.75s ease-in-out;
    overflow: hidden;
}

.slide.active {
	opacity: 1;
}

.color-slider-container .div{
	width: 4em;
	height: 4em;
	border-radius: 100%;
}

.color-slider-container .color-container {
 display: flex;
 flex-direction: column;
 margin-left: 0.6em;
}

.color-slider-container .div:hover{
	border: solid 1px var(--icon-color) ;
}

.color-slider-container .color-info{
	display: flex;
	flex-direction: column;
	padding: 1em;
}

.color-info p {
	padding: 0;
	margin: 0;
}