html,
body {
  height: 100%;
  // background-color: #eee;
}

html,
body,
input,
textarea,
buttons {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

/**
 * Layout CSS
 */

 #root {
   min-height: 100%;
   height: 100%;
  // height: auto;
 }
 #header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  transition: left 0.2s;
  background-color: white;
  left: 200px;
}
.mui-appbar {
  background-color: white !important;
  color: #444444;
}
#sidedrawer {
  color: white;
  background-color: #363b3f;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 200px;
  _left: -200px;
  overflow: auto;
  z-index: 2;
  transition: transform 0.2s;
}

#sidedrawer a{
  color: white;
  text-decoration: none;
}

#sidedrawer .sidedrawer-buttons li{
  padding-top: 22px !important;
}

#content-wrapper {
  background-color: #ecf0f5;
  min-height: 100%;
  overflow-x: hidden;
  margin-left: 200px;
  transition: margin-left 0.2s;
  
  /* sticky bottom */
  box-sizing: border-box;
  margin-bottom: -160px;
  padding-bottom: 160px;
  
  min-width: 800px;
}

/*
@media (min-width: 768px) {
  #header {
    left: 200px;
  }
  
  #sidedrawer {
    _transform: translate(200px);
  }
  
  #content-wrapper {
    margin-left: 200px;
  }
  
  body.hide-sidedrawer #header {
    left: 0;
  }
  
  body.hide-sidedrawer #sidedrawer {
    transform: translate(0px);
  }
  
  body.hide-sidedrawer #content-wrapper {
    margin-left: 0;
  }
  
  body.hide-sidedrawer #footer {
    margin-left: 0;
  }
}
*/

/**
 * Toggle Sidedrawer
 */
#sidedrawer.active {
  transform: translate(200px);
}



/**
 * Header CSS
 */

.sidedrawer-toggle {
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  line-height: 20px;
  margin-right: 10px;
}

.sidedrawer-toggle:hover {
  color: #fff;
  text-decoration: none;
}

/**
 * Sidedrawer CSS
 */

#sidedrawer .sidedrawer-buttons-wrapper {
  display: flex;
  flex-direction: column;
}

#sidedrawer .sidedrawer-buttons {
  display: flex;
  align-items: left;
  flex-direction: column;
}

#sidedrawer-brand {
  padding-left: 20px;
}

#sidedrawer ul {
  list-style: none;
}

#sidedrawer > ul {
  padding-left: 0px;
}

#sidedrawer > ul > li:first-child {
  padding-top: 15px;
}

#sidedrawer strong {
  display: block;
  padding: 15px 22px;
  cursor: pointer;
}

#sidedrawer strong:hover {
  background-color: #E0E0E0;
}

#sidedrawer strong + ul > li {
  padding: 6px 0px;
}

/**
 * Footer CSS
 */

 #footer {
  box-sizing: border-box;
  height: 160px;
  margin-left: 200px;
  transition: margin-left 0.2s;
  background-color: white;
}

#footer p {
  color: #6c757d;
  font-weight: 100;
}

#footer p b {
  color: black;
}

#footer a {
  color: #fff;
  text-decoration: underline;
}