@font-face {
	font-family: "poppins";
	src: url(../../assets/poppins/Poppins-Bold.ttf);
}

.pagina-productos-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 180px;
    min-height: 800px;
    padding: 1em;
    background-color: var(--white-color);
    padding-bottom: 3em;
}

.pagina-productos-container button{
    background-color: transparent;
    border: none;
    padding-left: 0.3em;
}
.pagina-productos-container  p{
    color: var(--grey-color);
}
.pagina-productos-container ul{
    list-style: none;
    padding-left: 1em;
    
}
.pagina-productos-container li:hover input{
    /* list-style:disc; */
    font-weight: bold;
}
li::marker{
    color: var(--icon-color);
}

.pagina-productos-container h2{
    margin-bottom: 1em;
    font-family: "poppins";
    font-weight: bold;
}
.pagina-productos-container .filtro-titulo{
    color: var(--icon-color);
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 2em;
}
.filtros-container{
    /* width: 30%; */
    padding-top: 10px;
    padding-left: 12em;
    padding-right: 8em;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.filtros-container .filtro-precio{
    position: relative;
}
.filtros-container .categorias{
    position: relative;
}

@media screen and (max-width: 1900px ) {
    .filtros-container{
        padding-top: 0;
        padding-left: 0; 
    }
}

.filtros-container li{
    padding-bottom: 1em;
    margin-bottom: 1em;
    cursor: pointer;
    /* max-width: 181px; */
    
}

.filtros-container li input{
    border: none;
    color: var(--grey-color);
    cursor: pointer;
    /* max-width: 181px; */
    position: absolute;
}

.filtros-container .categorias{
    margin-top: 3em;
}

.pagina-promos{
    max-width: 1100px;
}
.pagina-promos .productos{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 10px;
    grid-row-gap: 10px; 
    justify-items: center;
    align-items: center;
}

.pagina-promos .productos button{
    border: none;
    background-color: transparent;
}
.pagina-productos-container .button-container{
    padding-left: 1em;
    display: flex;
    /* width: 20%; */
    align-items:flex-end;
    justify-content: center;
}
.pagina-productos-container .cart-button{  
    background-color: var(--icon-color);
    color: var(--white-color);
    border: none;
    width: 10em;
    padding: 0.5em;
    margin-bottom: 1em;
    margin-top: 1em;
    fill: var(--white-color);
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0.5em;
}

/* slider 2 */

/* .slider-container-2{
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
    padding-bottom: 4em;
	border-bottom: solid #E8E8E8 ;  
    margin-left: 5em; 
    margin-right: 5em;
} */


 .pagina-promos.button{
    border: none;
	background: transparent;
}

.pagina-promos.slide {
	max-width: 100%;
	height: auto;
	opacity: 0;
	transition: opacity 0.75s ease-in-out;
    overflow: hidden;
}


 .pagina-promos .img-container-2{
    width: 100%;
    /* height: 100%;  */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
 .pagina-promos .img-container-2 img{
    width: 50%;
    border-radius: 0;
}
.pagina-promos .img-container-2 .text-container{
    margin: 1em;
}

.pagina-promos .img-container-2 button{
    background-color: var(--icon-color);
    color: var(--primary-color);
    border: none;
    padding: 1em;
    font-size: 14px;
}

.pagina-promos .img-container-2 p{
    color: var(--icon-color);
    font-weight: bold;
    text-transform: uppercase;
}

.pagina-promos .img-container-2 h3{
    font-family: "poppins-bold";
    font-size: 30pt;
    padding-bottom: 1em;
}