* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.container-loading {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0, 0.3);
}

