  .aside-cart {
      background: white;
      display: block;
      padding: 1em;
      padding-left: 0;
      color: black;
      height: 600px;
    }
    
    .aside-cart  .cart-title{
      width: 100%;
      font-size: 1.5em;
      text-align: start;
      padding-right: 5em;
      padding-left: 1em;
      font-weight: bold;
    }
  
    .aside-cart .cart-subtitle{
      color: var(--grey-color);
      font-size: 1.3em;
      text-align: start;
      padding-right: 5em;
      padding-left: 1em;
    }
  
    .aside-cart .products-ul{
      padding-left: 0;
      /* height: 800px; */
    }
    .aside-cart li {
      padding-top: 0.5em;
      padding-bottom: 1em;
      padding-right: 0.5em;
      padding-left: 0.5em;
      display: flex;
      justify-content: space-around;
      background-color: var(--background-color);
      margin-bottom: 1em;
      max-height: 13em;
      /* min-width: 370px; */
    }
    
    .aside-cart li img{
      display: flex;
      align-items: center;
      
    }
    .aside-cart .buttonx-container{
      width: 100%;
      display: flex;
      justify-content:flex-end;
    }
  
    .aside-cart .buttonx{
      background-color: red;
      color: var(--background-color);
      width:1.3em;
      height:1.3em;
      border-radius: 50px;
      border: none;
      font-size:12px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .aside-cart .item-img{
      width: 100px;
      height: 120px;
    }
  
    .aside-cart .item-footer{
      display: flex;
      gap: 8px;
      justify-content:space-between;
      padding-top: 1em;
      padding-left: 1em;
      font-size: 1.2em;
    }
  
    .aside-cart .item-footer .price{
      padding-right: 3em;
    }
  
    .aside-cart .container-right{
      display: flex;
      flex-direction: column;
      justify-content:left;
      padding-right: 5em;
    }
  
    .aside-cart footer {
      display: flex;
      gap: 8px;
      justify-content:space-around;
      align-items:center;
    }
    
    .aside-cart footer button {
      background-color: transparent;
      border: none;
      color: var(--icon-color);
    }
  
    .aside-cart .subtotal-container{
      
      display: flex;
      flex-direction: column;
      border-top: 1px solid var(--grey-color);
      padding-top: 16px;
    }
  
    .aside-cart .subtotal{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1em;
      margin-bottom: 1em;
    }
  
    .aside-cart .total{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.5em;
      margin-bottom: 1em;
    }
  
    .aside-cart .subtotal-container .button{
      width: 100%;
      background-color: var(--icon-color);
      color: var(--primary-color);
      border: none;
      border-radius: 0;
      padding: 1em;
      padding-left: 2em;
      padding-right: 2em;
      font-weight: bold;
    }
  
    .aside-cart .empty-container{
      min-height: 50em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1.1em;
    }
  
    .aside-cart .empty-container .p-2{
      color: var(--icon-color);
    }
  
    .aside-cart .cart-header{
      display: flex;
      justify-content:space-between;
      align-items: center;
      margin-bottom: 2em;
    }
    .aside-cart .cart-header div{
      display: flex;
      justify-content:flex-start;
      align-items: center;
      gap: 1em;
    }
    .aside-cart .cart-title-container{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .aside-cart .cart-title-container h2{
      padding: 0;
      margin: 0;
      font-size: 1.5em;
    }
    
    @media screen and (max-width:1440px){
      .empty-container{
        min-height: 40em;
      }
    }