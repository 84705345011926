/*table index*/
table.table-index{
  
}

table.table-index tr {
  border: 10px solid #ecf0f5;
}

table.table-index.quinary tr {
  border: 10px solid #fff3fb;
}

table.table-index tr td {
  background-color: white;
}

table.table-index tr th {
  text-align: left;
}

table.table-index tr td {
  text-align: left;
}

table.table-index tr td.centered {
  text-align: center;
}

table.table-index tr td a {
  color: black;
  text-decoration: none;  
}

table.table-index tr td a.action {
  font-weight: 600;
}

/*header index*/

.header-index{
  background-color: white;
}

.header-index .search{
  border-radius: 20px;
  border: 1px solid #e3e3e3;
  display: flex;
  height: 30px;
  width: 350px;
  flex-direction: row;
}

.header-index .search input{
  border: white solid 0px;
  flex: 1;
}

.header-index a {
  color: #6c757d;
  border-bottom: 3px solid transparent;
  text-decoration: none;
  font-weight: 100;
}

.header-index .primary a.active {
  border-bottom: 3px solid #5cb8b2;
}

.header-index .primary a:hover {
  color: #5cb8b2;
}

.header-index .secondary a.active {
  color: #6c757d;
  border-bottom: 3px solid #ffae4d;
}

.header-index .secondary a:hover {
  color: #ffae4d;
}

.header-index .tertiary a.active {
  border-bottom: 3px solid #f3277a;
}

.header-index .tertiary a:hover {
  color: #f3277a;
}

.header-index .quaternary a.active {
  border-bottom: 3px solid #ff4343;
}

.header-index .quaternary a:hover {
  color: #ff4343;
}

.header-index .quinary a.active {
  border-bottom: 3px solid #de1994;
}

.header-index .quinary a:hover {
  color: #de1994;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: #5cb8b2;
}

.breadcrumb-item .primary + .breadcrumb-item .primary::before {
  color: #5cb8b2;
}

.breadcrumb-item-secondary + .breadcrumb-item-secondary::before {
  color: #ffae4d;
}

.breadcrumb-item-tertiary + .breadcrumb-item-tertiary::before {
  color: #f3277a;
}

.breadcrumb-item-quaternary + .breadcrumb-item-quaternary::before {
  color: #ff4343;
}

.breadcrumb-item-quinary + .breadcrumb-item-quinary::before {
  color: #de1994;
}

.breadcrumb-item a {
  color: black;
  text-decoration: none;
  font-style: italic;
  font-weight: 100;
}

/* abm form */
div.abm-form form {
  flex: 1;
  padding-top: 20;
}
div.abm-form button {
  color: white !important;
  border-radius: 25px !important;
  min-width: 112px;
}



textarea:focus, input:focus, a:focus, button:focus {
  outline: none;
}

button:focus {
  border: 0px solid transparent !important;
}
/**
 * Remove focus styles for non-keyboard focus.
 */
 :focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

/**
 * Cross-browser styles for explicit focus via 
 * keyboard-based (eg Tab) navigation or the
 * .focus-visible utility class.
 */
:focus,
.focus-visible:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

.tab-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tab-content.quinary {
  background-color: #fff3fb;
}

div.tab-pane.active {
  display: flex;
  flex-direction: column;
  flex: 1;
}

$btn-focus-width: 0;
$enable-shadows: 0;

// scss-docs-start btn-variant-mixin
@mixin input-decorator-variant(
  $color,
  $value
) {
  .input-decorator-#{$color} {
    color: $value;
    background: white;
  }
  .input-decorator-#{$color}-inverted {
    color: white;
    background: $value;
  }

}



// scss-docs-start btn-variant-loops
@each $color, $value in $theme-colors {
  @include input-decorator-variant($color, $value);
}

.input-decorator {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.remove-file {
  margin-left: -2rem;
  cursor: pointer;
}

.multiple-file {
  background-color: black;
  color: white;
  border-radius: 2rem;
  margin-right: 1rem;
  padding: 0.25rem 0.5rem;
}

.icon {
  height: 1.25rem;
  width: 1.25rem;
  padding: 0.15rem;
}

.icon-sm {
  height: 1rem;
  width: 1rem;
  padding: 0.15rem;
  font-size: 0.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.disable-input {
  background-color: #ccc !important;
}

.disable-input input {
  background-color: #ccc !important;
}

.small-labels label {
  font-size: 14px !important;
}

.tag-input {
  border: none;
  width: -webkit-fill-available;
}

.files-container {
  height: 100%;
  padding: 1rem 3rem 1rem 1rem !important;
  margin: 0 !important;
}