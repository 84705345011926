.modalWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    color: black;
    z-index: 99;
  }
  
  .modal {
    background-color: white;
    padding: 40px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    max-width: 540px;
    width: 90%;
  }

  .title{
    padding-right: 5em;
    margin-bottom: 1em;
    font-weight: bold;
  }
  .subText{
    color: var(--grey-color);
    margin-bottom: 2em;
  }

  .inputContainer{
    display: flex;
    flex-direction: column; 
   }
  .iconInputContainer{
    width: 100%;
    display: flex;
    height: 3em;
  }
  .iconContainer{
   background-color: var(--background-color);
    display: flex;
    width: 8%;
    align-items: center;
    justify-content: center;
    height: 3em;
  }
 .input{
  border: solid 1px var(--border-bottom-color);
  padding-left: 0.5em;
  height: 3em;
  margin-bottom: 1em;
  width: 90%;
  background-color: transparent;
 }

 .labelContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 }
 .label{
  margin-bottom: 0.5em;
  font-weight: bold;
 }
 .linksContainer{
  display: flex;
  margin-top: 2em;
  margin-bottom: 0;
 }
 .modal .links{
  color: var(--icon-color);
  font-weight: bold;
  font-size: 1em;
  padding-left: 0.5em;
  text-decoration: none;
 }
 .modal .links:hover{
  color: black;
 }
 .buttonContainer{
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 2em;
  padding-bottom: 2em;
  border-bottom: 2px solid var(--border-bottom-color);
 }
 .buttonPrimary{
  border: none;
  height: 3em;
  background-color: var(--icon-color);
  color: var(--white-color);
 }
 .buttonSecondary{
  background-color: transparent;
  height: 3em;
  border: 3px solid var(--icon-color);
  color: var(--icon-color);
  font-weight: bold;
 
 }
 .buttonPassword{
  width: 10%;
 }
  .modalCloseButton {
    cursor: pointer;
    font-weight: bold;
    background-color: transparent;
    color: var(--icon-color);
    border: none;
    font-size: 1.5em;
    display: flex;
    justify-content: end;
    width: 100%;
  }
  .negrita{
    font-weight: bold;
    color: black;
  }

  select{
    border: 1px solid var(--border-bottom-color);
    color: var(--grey-color);
    font-size: 100%;
    width: 100%;
    height: 52px;
    padding: 1em;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}