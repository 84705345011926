.traker-container{
    margin: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 800px;
 }
.traker-container.traker-container .traker-form{
   display: flex;
   justify-content: center;
   width: 1000px;
 }
 /* .traker-container .title-registro{
   font-weight: bold;
   font-size: 1.5em;
 }
 .traker-container .subtitle{
   font-weight: bold;
   font-size: 1.2em;
   margin-top: 1em;
   margin-bottom: 1em;
 } */

 .traker-container input {
    border: 1px solid var(--border-bottom-color);
    padding-left: 0.5em;
    font-size:small;
    /* height: 3em; */
 }

.traker-container.submit-button{
    background-color: var(--icon-color);
    color: var(--primary-color);
    border: none;
    padding: 1em;
    margin: 0;
    margin-left: 4em;
    font-weight: bold;
    min-height: 4em;
    /* width: 200px; */
}

.traker-container .section-area {
   color: var(--icon-color);
   text-transform: uppercase;
   font-weight: bold;
 }
.traker-container .gris{
   color:var(--grey-color);
}

.traker-container .table-container{
   margin-top: 4em;
}
.traker-container table {
   border-collapse: collapse;
   border: 2px solid rgb(200,200,200);
   letter-spacing: 1px;
   font-size: 0.8rem;
 }
 
.traker-container  td, th {
   border: 1px solid rgb(190,190,190);
   padding: 10px 20px;
 }
 
.traker-container  th {
   background-color: rgb(235,235,235);
 }
 
.traker-container  td {
   text-align: center;
 }
 
.traker-container  tr:nth-child(even) td {
   background-color: rgb(250,250,250);
 }
 
.traker-container  tr:nth-child(odd) td {
   background-color: rgb(245,245,245);
 }
 
.traker-container  caption {
   padding: 10px;
 }
