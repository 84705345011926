
.checkout-container{
    margin-top: 10em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.checkout-container .title-checkout{
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 1em;
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 230px;
}
.checkout{
        display: grid;
        grid-template-columns: 60% 40%;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        justify-items: center;
        align-items: start;
}
.checkout input{
    border: 1px solid var( --border-bottom-color);
    padding-left: 0.5em;
}
.checkout label{
    color: var(--grey-color);
    display: flex;
    align-items: center;
}
.columna-izquierda{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30em;
}

.contenedor-option .left-container{
    width: 100%;
    padding-left: 1em;
    display: flex;
    align-items: center;
}
.medios-entrega-container h2{
    border-bottom: solid 1px var(--border-bottom-color);
    padding-bottom: 1em;
    font-weight: bold;
    font-size: 1.2em;
}

.title-form{
    width: 100%;
    border-bottom: solid 1px var(--border-bottom-color);
    padding-bottom: 1em;
    font-weight: bold;
    font-size: 1.2em;
    margin-top: 1em;
    margin-bottom: 1em;
    width: 800px;
}
.title-form span{
    color: var(--icon-color);
}

.contenedor-option{
    margin-top: 1em;
    width: 800px;
    display: grid;
    gap: 1em;
    padding-right: 5em;
    margin-top: 1em;
    margin-bottom: 1em;
    grid-template-columns: 85% 15%;
    justify-items: center;
    align-items: center;
    border: solid 1px var(--border-bottom-color);
    border-radius: 0.5em;
}

.option-title-container{
    margin-right: 7em; 
}

.option-title-container h3{ 
    font-size: 1em;
    padding: 0;
    margin: 0;
}
.checkbox{
    display: flex;
    gap: 1em;
    font-size: 1em;
}
.checkbox-2{
    margin-left: 5em;
}
.checkbox-2 input{
    appearance: none;
    width: 1.5em;
    height: 1.5em;
    border-radius: 0.2em;
}

.checkbox input{
    appearance: none;
    border: 1px solid var(--grey-color);
    width: 1.5em;
    height: 1.5em;
    border-radius: 0.2em;
}

.checkbox input:checked{
    background-color: var(--icon-color);
}

.columna-derecha h2{
    font-size: 1.2em;
    font-weight: bold;
}
.form-checkout{
    margin-bottom: 4em;
    margin-right: 2em;
}
.form-checkout-1{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
}
.form-checkout-2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
}
.form-text-area{
    display: grid;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}
.submit-button{
    background-color: var(--icon-color);
    color: var(--primary-color);
    border: none;
    padding: 1em;
    padding-left: 2em;
    padding-right: 1em;
    margin-top: 1em;
    font-weight: bold;
    width: 200px;
}
.input-checkox-container{
    display: flex;
    align-items: center;
    gap: 1em;
}

.input-form{
  height: 3em;
}
.text-area{
  resize: none;
  width: 800px;
  border: 1px solid var(--border-bottom-color);
  height: 200px;
}

/* checkbox */
/* Create a custom checkbox */
/* Hide the default checkbox */
.option-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .option-checkbox-container {
    display: flex;
    gap: 10px;
  }



  /* Create a custom checkbox */

  .checkmark-option {
    position: relative;
    box-shadow: var(--border-bottom-color) 0px 0px 0px 2px;
    background-color: var(--white-color);
    height: 20px;
    width: 20px;
    margin-right: 10px;
    flex-shrink: 0;
    margin-top: -1px;
    transition: all 0.2s ease 0s;
    cursor: pointer;
    transform-origin: 0px 10px;
    border-radius: 4px;
    margin: -1px 10px 0px 0px;
    padding: 0px;
    box-sizing: border-box;
  }
  
  .option-checkbox-container input:checked ~ .checkmark-option {
    background-color: var(--white-color);
    height: 20px;
    width: 20px;
    margin-right: 10px;
    flex-shrink: 0;
    margin-top: -1px;
    transition: all 0.2s ease 0s;
    cursor: pointer;
    transform-origin: 0px 10px;
    border-radius: 4px;
    margin: -1px 10px 0px 0px;
    padding: 0px;
    box-sizing: border-box;
  }
  
  .checkmark-option:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .option-checkbox-container input:checked ~ .checkmark-option:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .option-checkbox-container .checkmark-option:after {
    left: 0.45em;
    top: 0.25em;
    width: 0.25em;
    height: 0.5em;
    border: solid var(--icon-color);
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
    transition: all 500ms ease-in-out;
  }

/* checkbox 2 */
.input-checkox-container .checkmark-option {
  position: relative;
  box-shadow: rgb(255, 84, 0) 0px 0px 0px 2px;
  background-color: rgba(16, 16, 16, 0.5);
  height: 20px;
  width: 20px;
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: -1px;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  transform-origin: 0px 10px;
  border-radius: 4px;
  margin: -1px 10px 0px 0px;
  padding: 0px;
  box-sizing: border-box;
}

.input-checkox-container input:checked ~ .checkmark-option {
  box-shadow: rgb(255, 84, 0) 0px 0px 0px 2px;
  background-color: rgba(245, 24, 24, 0.5);
  height: 20px;
  width: 20px;
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: -1px;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  transform-origin: 0px 10px;
  border-radius: 4px;
  margin: -1px 10px 0px 0px;
  padding: 0px;
  box-sizing: border-box;
}

 .input-checkox-container .checkbox-wrapper input[type="checkbox"] {
    display: none;
  }
  .input-checkox-container .checkbox-wrapper label {
    --size: 1em;
    position: relative;
    display: block;
    width: var(--size);
    height: var(--size);
    margin: 0 auto;
    background-color: var(--white-color);
    border: 1px solid var(--grey-color);
    border-radius: 100%;
    cursor: pointer;
    z-index: 1;
  }
  
  .input-checkox-container .checkbox-wrapper label:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: calc(var(--size) * .55);
    height: calc(var(--size) * .55);
    margin: 0 auto;
    background-color: var(--icon-color);
    transform: translateY(-50%);
    border-radius: 50%;
    box-shadow: inset 0 var(--shadow) #ffbeb8;
    transition: 0.2s ease width, 0.2s ease height;
  }
  .input-checkox-container .checkbox-wrapper label:active {
    transform: scale(0.9);
  }
  .input-checkox-container .checkbox-wrapper input[type="checkbox"]:checked + label:before {
    background-color: var(--icon-color);
    width: calc(var(--size) * .9);
    height: calc(var(--size) * .9);
  }

  .icon-checkout{
    width: 8em;
  }
 .icon-checkout-mercado{
  width: 4em;
  margin-left: 2em;
  margin-right: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
 }
  @media screen and (max-width:1440px){
    .columna-izquierda{
      margin-left: 1em;
    }
  }