@font-face {
	font-family: "poppins-bold";
	src: url(../../assets/poppins/Poppins-Bold.ttf);
}

.slider-container-2{
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
    padding-bottom: 4em;
	border-bottom: solid #E8E8E8 ;  
    margin-left: 5em; 
    margin-right: 5em;
}


.slider-container-2 .button{
    border: none;
	background: transparent;
}

.slide {
	max-width: 100%;
	height: auto;
	opacity: 0;
	transition: opacity 0.75s ease-in-out;
    overflow: hidden;
}

.slide.active {
	opacity: 1;
}

.slider-container-2 .img-container-2{
    width: 100%;
    /* height: 100%;  */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.slider-container-2 .img-container-2 img{
    width: 50%;
    border-radius: 0;
}
.slider-container-2 .img-container-2 .text-container{
    margin-left: 1em;
    margin: 3.5em;
}

.slider-container-2 .img-container-2 button{
    background-color: var(--icon-color);
    color: var(--primary-color);
    border: none;
    padding: 1em;
}

.slider-container-2 .img-container-2 p{
    color: var(--icon-color);
    font-weight: bold;
    text-transform: uppercase;
}

.slider-container-2 .img-container-2 h3{
    font-family: "poppins-bold";
    font-size: 30pt;
    padding-bottom: 1em;
}