div.m-alert{
  position:fixed;
  top: 20px;
  opacity: 1;
  transition: right 0.2s;
  z-index: 10000;
}

div.m-alert.m-show{
  right: 20px;
}

div.m-alert.m-hide {
  right: -100vw;
}