.categ-container{
  display: flex;
  max-width: 500px;
}
.categ-tags-container{
  display: flex;
  max-width: 400px;
  flex-wrap: wrap;
}

.categ-tags{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 7px 0;
  margin-right: 5px;
  margin-left: 5px;
  padding: 2px 15px 2px 15px;
  border-radius: 15px;
  background-color: var(--bs-secondary);
  white-space: nowrap;
  color: white;
  font-weight: normal;
  max-width: 5em;
}

.categ-tags .delete-button{
        min-width: 10px ;
        display: flex;
        border: none;
        background-color: unset;
        cursor: pointer;
        color: white;
        padding: 2px;
        margin-left: 4px;
}