.cart {
    background: white;
    display: block;
    padding: 1em;
    position: fixed;
    right: 0px;
    bottom: 0;
    width: 370px;
    overflow-y: scroll;
    z-index: 99;
    height: 100%;
    color: black;
  }
  
  .cart-title{
    width: 100%;
    font-size: 1.5em;
    text-align: start;
    padding-right: 5em;
    font-weight: bold;
  }

  .cart-subtitle{
    color: var(--grey-color);
    font-size: 1.3em;
    text-align: start;
    padding-right: 5em;
  }
  
  /* .cart img {
    aspect-ratio: 16/9;
    width: 100%;
  } */
  .cart ul{
    padding-left: 0;
  }
  .cart li {
    padding-top: 0.5em;
    padding-bottom: 1em;
    padding-right: 0.5em;
    padding-left: 0.5em;
    display: flex;
    justify-content: space-around;
    background-color: var(--background-color);
    margin-bottom: 1em;
    max-height: 13em;
  }
  
  .cart li img{
    display: flex;
    align-items: center;
    
  }
  .buttonx-container{
    width: 100%;
    display: flex;
    justify-content:flex-end;
  }

  .buttonx{
    background-color: red;
    color: var(--background-color);
    width:1.3em;
    height:1.3em;
    border-radius: 50px;
    border: none;
    font-size:12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item-img{
    width: 100px;
    height: 120px;
  }

  .cart .item-footer{
    display: flex;
    gap: 8px;
    justify-content:space-between;
    /* padding-top: 3em; */
    font-size: 1.2em;
  }

  .item-footer .price{
    padding-right: 3em;
  }

  .container-right{
    display: flex;
    flex-direction: column;
    justify-content:left;
    padding-right: 5em;
  }

  .cart footer {
    display: flex;
    gap: 8px;
    justify-content:space-around;
    align-items:center;
  }
  
  .cart footer button {
    background-color: transparent;
    border: none;
    color: var(--icon-color);
  }
  
  .cart-button {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 4px;
    gap: 1em;
    background-color: transparent;
    border: none;
    color: var(--primary-color);
  }

  .subtotal-container{
    
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--grey-color);
    padding-top: 16px;
  }

  .subtotal{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5em;
    margin-bottom: 1em;
  }

  .subtotal-container .button{
    width: 100%;
    background-color: var(--icon-color);
    color: var(--primary-color);
    border: none;
    border-radius: 0;
    padding: 1em;
    padding-left: 2em;
    padding-right: 2em;
    font-weight: bold;
  }

  .empty-container{
    min-height: 50em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
  }

  .empty-container .p-2{
    color: var(--icon-color);
  }

  .cart-header{
    display: flex;
    justify-content:space-between;
    align-items: center;
    margin-bottom: 2em;
  }
  .cart-header div{
    display: flex;
    justify-content:flex-start;
    align-items: center;
    gap: 1em;
  }
  .cart-title-container{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .cart-title-container h2{
    padding: 0;
    margin: 0;
    font-size: 1.5em;
  }
  
  @media screen and (max-width:1440px){
    .empty-container{
      min-height: 40em;
    }
  }
  /* .cart-button-label ~ input:checked ~ .cart {
    height: 100%;
    display: block;
  } */