@font-face {
	font-family: "roboto" ;
	src: url(../../assets/Roboto/Roboto-Regular.ttf);
}
 .menu-horizontal{
    background-color: var(--nav-color);
    margin: 0;
    padding: 1em 2em;
    /* padding-bottom: 1em; */
    /* grid-template-rows: repeat(auto-fill, minmax(4em, 1fr));
    grid-template-columns: repeat(auto-fill, minmax(4em, 1fr));
    gap: 2em;
    padding:  0 6em; */
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */
    grid-template-columns: repeat(auto-fill, minmax(8em, 1fr));
    grid-template-rows: 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    justify-items: start;
    align-items: center;
  }
  .menu-horizontal a{
    color: var(--primary-color);
    text-decoration: none;
    font-family: "roboto";
    font-size: 12px;
  }
  .menu-horizontal a:hover{
    border-bottom: solid var(--icon-color);
  }
  .menu-horizontal .contenedor-relative{
    position: relative;
    height: 1.5em;
	  /* z-index: 9; */
  }
  
  ul li{
    list-style: none;
  }
  .menu-container{
    position: relative;
    z-index: 99;
  }
  .menu-vertical{
      position: absolute;
      display: none;
  }
  .menu-final{
    display: none;
  }
  .menu-horizontal li:hover .menu-vertical {
    display: flex;
    flex-direction: column;
  }
  .triangulo-equilatero{
    display: none;
  }
  .menu-horizontal li:hover .triangulo-equilatero{
    display: flex;
  }
  
  
  /* .menu-vertical li:hover .menu-final{
    display: flex;
  } */

/* .menu-horizontal .visible{
    display: flex;
} */
