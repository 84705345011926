table {
    border-collapse: collapse;
    width: 100%;
    border: none; /* Eliminar todos los bordes de la tabla */
  }
 
  th, td {
    text-align: start;
    padding: 8px;
    border: none; /* Eliminar todos los bordes de las celdas */
  }
  
  th {
    background-color: #f2f2f2; /* Color de fondo para las celdas de encabezado */
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2; /* Color de fondo para filas pares */
  }
  
  /* Opcional: resaltar fila al pasar el ratón */
  tr:hover {
    background-color: #ddd;
  }
  