.img-container-product {
            /* width: 200px; */
            /* width: 200px;  */
            display: flex;
            flex-direction: row;
            /* border: solid 1px grey; */
        }
.img-container-product .main-img-container{
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.img-container-product .main-image {
            width: 100%;
            /* border: solid 1px green; */
            
        }

.img-container-product .secondary-container {
            display: flex;
            flex-direction: column;
            width: 300px;
            justify-content: space-between; 
            gap: 10px;
            /* border: solid 1px blue; */
        }

.img-container-product .secondary-image{
          margin-right: 10px;
          border-radius: 10px;
          aspect-ratio: 1 / 1;
          width: 150px;
        }
/* .img-container-product .img-container-2{
    width: 100%;
 } */
      