
@font-face {
	font-family: "roboto" ;
	src: url(../../assets/Roboto/Roboto-Regular.ttf);
}
@font-face {
	font-family: "poppins";
	src: url(../../assets/poppins/Poppins-Bold.ttf);
}
/* @font-face {
	font-family: "";
	src: url(/idraet-ecommerce/src/components/home-page/assets/);
} */

.slider-container-1 {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	/* margin-top: 1em; */
	/* padding: 0;
	margin: 0; */
	background-color: #E8E8E8;
}

.slider-container-1 button{
    border: none;
	background: transparent;
}

.slide {
	max-width: 100%;
	height: auto;
	opacity: 0;
	transition: opacity 0.75s ease-in-out;
    overflow: hidden;
}

.slide.active {
	opacity: 1;
}

.slider-container-1 .contenedor-releative-1{
	position: relative;
	width: 80vw;
	height: 100%;
} 

.contenedor-releative-1 img{
	width: 100%;
}
.slider-container-1 .blue-button{
	font-family: "roboto";
	position: absolute;
	bottom: 0;
	left: 18em;
	font-size: large;
	margin-bottom: 4em;
	padding: 0;
	border-radius: 1.5em;
	width: 15em;
	text-decoration: none;
	text-align: center;
}

.slider-container-1 .title-container{
	position: absolute;
	top: 3em;
	left: 20em;
	width: 40%;
	padding: 0;
	margin: 0;
}

.slider-container-1 .slider-1-title{
	font-family: "poppins";
	text-transform: uppercase;
	line-height: 1em;
	font-size: 80px;
	color: var(--secondary-color);
	display: flex;
	flex-direction: row;
	padding: 0;
	margin: 0;
	color: #50504B;
}

.slider-container-1 .slider-1-p{
	font-family: "roboto";
	text-transform: lowercase;
	font-size: 2em;
	padding: 0;
	margin: 0;
	color: #50504B;
}

@media screen and (max-width:1440px){
	.slider-container-1 .slider-1-title{
		text-wrap:balance;
		font-size: 3em;
	}
  }