/* .file-upload-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .file-upload-container ul{
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .file-upload-container li{
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
 
  .file-input {
    display: none;
  }
  
  .file-list {
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    border-radius: 4px;
    width: 100%;
    overflow-y: auto;
    max-height: 200px;
  }
  
  .file-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .delete-button {
    margin-left: 10px;
    border: none;
    background-color: var(--icon-color);
  }
  
  .add-files-button {
    border: none;
    height: 2em;
    color: var(--white-color);
    background-color: var(--icon-color);
  }
   */
   .file-upload-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--border-bottom-color);
    height: 3em;
   }
   .file-input-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
   }
   
   .file-upload-container .file{
    padding: 0;
    margin: 0;
    color: var(--grey-color);
   }
   
  /* Estilo para el botón de eliminar */
  .file-upload-container .button-add {
  background-color: var(--icon-color);
  color: #fff;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  height: 3em;
  width: 2em;
}

.buttonx{
  background-color: red;
  color: var(--background-color);
  width:1.3em;
  height:1.3em;
  border-radius: 50px;
  border: none;
  font-size:12px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1em;
}
