
/* make the customizations */


$primary:       #5cb8b2;
$secondary:     #363b3f;
$success:       #5DC1B9;
$info:          #0dcaf0;
$warning:       #e09244;
$danger:        #e14d43;
$light:         #f8f9fa ;
$dark:          #212529;



/* import bootstrap to set changes */
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

@import "_layout.scss";
@import "_site.scss";