@font-face {
	font-family: "poppins-reg";
	src: url(../../assets/poppins/Poppins-Regular.ttf);
}
@font-face {
	font-family: "poppins-bold";
	src: url(../../assets/poppins/Poppins-Bold.ttf);
}
@font-face {
	font-family: "roboto";
	src: url(../../assets/Roboto/Roboto-Bold.ttf);
}
@font-face {
	font-family: "roboto-light";
	src: url(../../assets/Roboto/Roboto-Light.ttf);
}

.slider-container-3 {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-bottom: 4em;
	border-bottom: solid #E8E8E8 ;
}

.slider-container-3 .button{
    border: none;
	background: transparent;
}

.slide {
	max-width: 100%;
	height: auto;
	opacity: 0;
	transition: opacity 0.75s ease-in-out;
    overflow: hidden;
}

.slide.active {
	opacity: 1;
}

.slider-container-3 .img-container{
    width: 100%;
    height: 100%;
	margin-left: 0.5em;
	margin-right: 0.5em;
    
}

.img-container-3 .description-container{
	padding-left: 1em;
	padding-top: 2em;
}

.img-container-3 .description-container p{
	font-size: 19pt;
	font-family: "roboto-light";
	color: var(--grey-color);
}

.slider-container-3 .contenedor-relative{
    position: relative;
}
.slider-container-3 .img-container-3 img{
    width: 25em;
    height: 15.6em;
}

.contenedor-relative button{
    position: absolute;
    left: 72%;
    bottom: 0;
}
.contenedor-relative p{
	padding: 0;
	margin: 0;
	font-family: "roboto";
	font-weight:bold;
	text-transform: uppercase;
}
.slider-container-3 .button-s3{
	font-weight:bold;
	background-color: #FFFFFF;
	color: var(--icon-color);
	border-radius: 1em 0 0 0;
	width: 8em;
	font-family: "roboto";
	font-size: 12pt;
	text-transform: uppercase;
	padding: 0.3em;
}

.img-container-3 h3{
	font-weight:normal;
	font-family: "poppins-reg";
	font-size: 21pt;
	margin-bottom: 0;
}

.img-container-3 span {
	font-family: "poppins-bold";
	font-size: 21pt;
}
