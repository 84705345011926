.footer-container{
    color: var(--primary-color);
    background-color: #2B2B38;
    width: 100%;
    bottom: 0;
}
.footer-container a{
    color: var(--white-color);
}
.footer-container .footer p{
    color: var(--white-color);
    margin-bottom: 0;
}
.footer{
    display: flex;
    justify-content: space-evenly;
    text-align: center;
}

.footer-container .footer-1{
    display: flex;
    flex-direction: row;
}

.footer-container .footer-1 div{
    display: flex;
    flex-direction: column;
}
.footer-container .footer-icon-container{
    display: flex;
    flex-direction: column;
    width: 33%;
}
.footer .footer-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3em;
    margin-bottom: 1em;
    padding-right: 1em;
    border-right: solid 0.5px var(--primary-color);
   
}

.footer .icon-3{
    border-right: none;
}

.footer-container .footer-2{
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 1em;
    border-top: solid  0.5px var(--primary-color);
}

.footer-container .footer-2 p{
    margin-right: 2em;

}
.footer-container .footer-3{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 10em;
}
.footer-container .footer-3 p{
    color: #909aa1;
}
.footer-container .footer-3 div{
    display: flex;
    align-items:center;
    justify-content: center;
    margin-left: 6em;
    gap: 1em;
    
}

/* .footer-container .footer-3 a{
    width: 1em;
    margin-right: 2em;
    
} */
